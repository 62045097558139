import React, { useEffect, useState } from "react";
import logo from "../../assets/Logo/nixieeLogo.png";
import axios from "axios";

const NavBar = (props) => {
  const [accountName, setAccountName] = useState("");
  const [account, setAccount] = useState({});
  const [isLogin, setIsLogin] = useState(false);
  const [steemPower, setSteemPower] = useState(0);
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    props.handleSideBar(showSideBar);
  }, [showSideBar]);

  useEffect(() => {
    const localInfo = localStorage.getItem("accountInfo");
    if (localInfo) {
      setAccount(JSON.parse(localInfo)[0]);
      setIsLogin(true);
    }
  }, []);

  useEffect(() => {
    props.getAccount(account);
  }, [account]);

  const getAccount = async (accountName) => {
    const account = await axios.post("https://api.justyy.com", {
      jsonrpc: "2.0",
      method: "condenser_api.get_accounts",
      params: [[accountName]],
      id: Date.now(),
    });
    if (Array.isArray(account.data.result)) {
      return account.data.result;
    } else {
      return [];
    }
  };

  const login = async () => {
    if (!(accountName.length > 3) && !(accountName.length < 16)) return;
    if (!window.steem_keychain) return; // cüzdanı yüklemesi gerektiğini gösteren hata mesajı gösterilebilir

    const account = await getAccount(accountName);

    // burada kullanıcı bulunamadı.
    if (!(account.length > 0)) return;
    const status = await _connect(account[0].name, "nixiee");

    if (status) {
      setAccount(account[0]);
      setIsLogin(true);
      localStorage.setItem("accountInfo", JSON.stringify(account));
    }
  };

  const logout = async () => {
    const status = await _disconnect(account?.name, "nixiee");

    if (status) {
      setAccount([]);
      setIsLogin(false);
      localStorage.removeItem("accountInfo");
    } else {
      // burada bir hata mesajı gösterilebilir. bir şeyler ters gitti diye.
    }
  };

  const delegate = async () => {
    if (isLogin) {
      if (typeof steemPower !== "number") return;

      const response = await _delegate(
        account?.name,
        "nixiee",
        steemPower.toFixed(3)
      );

      if (response.success) {
        // işlem başarılı pop-up gibi bir şeyle başarılı olduğunu belirtmek gerek
        alert("Transanction Succesfull!");
      } else {
        // işlem başarısız hata mesajı response.error ile gösterilebilir.
        alert("Transanction Error!");
      }
    } else if (!isLogin) {
      alert("Please connect your wallet!");
    }
  };

  const _connect = (accountName, rootName) => {
    return new Promise((resolve) => {
      window.steem_keychain.requestAddAccountAuthority(
        accountName,
        rootName,
        "Posting",
        1,
        (res) => {
          resolve(res.success);
        }
      );
    });
  };

  const _disconnect = (accountName, rootName) => {
    return new Promise((resolve) => {
      window.steem_keychain.requestRemoveAccountAuthority(
        accountName,
        rootName,
        "Posting",
        (res) => {
          resolve(res.success);
        }
      );
    });
  };

  const _delegate = (userName, rootAccountName, steemPower) => {
    return new Promise((resolve) => {
      window.steem_keychain.requestDelegation(
        userName,
        rootAccountName,
        steemPower,
        "SP",
        (res) => {
          resolve(res);
        }
      );
    });
  };

  const date = new Date();
  return (
    <>
      <div className="md:pl-[220px] py-4 w-[100%]">
        <div className="flex justify-between items-center gap-6 flex-col lg:flex-row w-full">
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-center items-center gap-4">
              <img
                className="w-[40px] h-[40px] rounded-full border-blue-300 border-[1px]"
                src={
                  account?.posting_json_metadata
                    ? JSON.parse(account?.posting_json_metadata).profile
                        .profile_image
                    : logo
                }
                alt="user logo"
              />
              <div>
                <h3>{account?.name ? account?.name : "Guest!"}</h3>
                <h3 className="text-[12px]">
                  {date.toLocaleDateString("en-US", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </h3>
              </div>
            </div>
            <div
              onClick={() => setShowSideBar(!showSideBar)}
              className="flex flex-col md:hidden mr-10 gap-2 cursor-pointer"
            >
              <span className="border-[2px] border-black w-[30px]"></span>
              <span className="border-[2px] border-black w-[30px]"></span>
              <span className="border-[2px] border-black w-[30px]"></span>
            </div>
          </div>

          <>
            {isLogin ? (
              <div className="flex justify-center items-center w-full">
                <div className="flex items-center justify-center md:mr-5 gap-4 flex-col md:flex-row w-full">
                  <div className="flex justify-center items-center w-full md:mr-0 px-3">
                    <div className=" w-full flex gap-1 border-[1px] border-blue-400 h-[45px] justify-center items-center px-1 rounded-[5px]">
                      <h3 className="text-[16px] font-bold text-gray-500">
                        Balance:
                      </h3>
                      <h3 className="flex justify-center items-center text-[14px] text-gray-500 whitespace-nowrap">
                        {account.balance}
                      </h3>
                    </div>
                    <button
                      onClick={logout}
                      className="block md:hidden bg-red-300 ml-2 hover:bg-red-500 text-white font-bold py-2 px-4 rounded h-[45px]"
                    >
                      Logout
                    </button>
                  </div>
                  <div className="w-full flex justify-center items-center px-3">
                    <span className="hidden md:block text-lg whitespace-nowrap">
                      Steem Power:&nbsp;&nbsp;&nbsp;
                    </span>
                    <input
                      className="w-full md:w-[120px] h-[45px] px-3 py-2 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500"
                      placeholder="9999.999"
                      type="number"
                      onChange={(e) => setSteemPower(Number(e.target.value))}
                    />
                    <button
                      onClick={delegate}
                      className="bg-green-700  hover:bg-green-500 ml-2 text-white font-bold py-2 px-4 rounded h-[45px]"
                    >
                      Delegate
                    </button>
                    <button
                      onClick={logout}
                      className="hidden md:block bg-red-300 mx-2 hover:bg-red-500 text-white font-bold py-2 px-4 rounded h-[45px]"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex justify-center items-center">
                  <span className="hidden md:block text-lg whitespace-nowrap">
                    Steem Power:&nbsp;&nbsp;&nbsp;
                  </span>
                  <input
                    className="w-full md:w-[120px] h-[45px] px-3 py-2 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500"
                    placeholder="9999.999"
                    type="number"
                    onChange={(e) => setSteemPower(Number(e.target.value))}
                  />
                  <button
                    onClick={delegate}
                    className="bg-green-700 mx-2 hover:bg-green-500 text-white font-bold py-2 px-4 rounded h-[45px]"
                  >
                    Delegate
                  </button>
                </div>
                <div className="flex">
                  <input
                    className="appearance-none w-full md:w-[120px] block w-48 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setAccountName(e.target.value)}
                  />
                  <button
                    onClick={login}
                    className="bg-blue-300 mx-2 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
                  >
                    Login
                  </button>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default NavBar;
