import { useState, useEffect } from "react";

function getRemainingTime() {
  const now = new Date(new Date().toLocaleString("en-US", { timeZone: "Europe/Istanbul" }));
  
  const targetTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    0,
    5,
    0,
    0
  );
  const remainingTime = targetTime - now;

  return remainingTime <= 0 ? 0 : remainingTime;
}

export default function useCountdown() {
  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newReamingTime = getRemainingTime();

      if (newReamingTime <= 0) {
        setRemainingTime(24 * 60 * 60 * 1000 - 1);
      } else {
        setRemainingTime(newReamingTime);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const seconds = Math.floor((remainingTime / 1000) % 60);
  const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
  const hours = Math.floor((remainingTime / 1000 / 60 / 60) % 24);

  return {
    hours,
    minutes,
    seconds,
  };
}
