import React, { useEffect, useState } from "react";
import useCountdown from "../../hooks/useCountdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccount,
  getAllAccounts,
  getSteemUSDParity,
  getTransactionsByName,
  getTransactionsTransfers,
  getTransactionsTransfersByAccount,
  getTransactionsVotes,
  getTransactionsVotesByAccount,
} from "../../redux/slices/BotSlice";
import DataTable from "react-data-table-component";
import axios from "axios";

const Table = (props) => {
  const { contentID, account } = props;
  const [calculate, setCalculate] = useState();
  const [calculateResponse, setCalculateResponse] = useState();
  const [globalProps, setGlobalProps] = useState();
  const [getEstimateReward, setGetEstimateReward] = useState();
  const [accountPower, setAccountPower] = useState();
  const [percentage, setPercentage] = useState();
  const [sortedVotes, setSortedVotes] = useState();
  const [sortedTransactions, setSortedTransactions] = useState();

  const dispatch = useDispatch();
  const { hours, minutes, seconds } = useCountdown();

  const {
    getTransactionsTransfersData,
    getAccountData,
    getTransactionsByNameData,
    getTransactionsVotesData,
    getTransactionsTransfersByAccountData,
    getTransactionsVotesByAccountData,
    isGetTransactionsTransfersLoading,
    isGetTransactionsVotesLoading,
    isGetAllAccountLoading,
    getAllAccountsData,
  } = useSelector((store) => store.bot);



  useEffect(() => {
    if (getTransactionsVotesData) {
      const sortedVotes = getTransactionsVotesData
        .map((item) => {
          return { ...item, postTime: item.postTime };
        })
        .sort((a, b) => {
          return b.postTime - a.postTime;
        });

      setSortedVotes(sortedVotes);
    }
  }, [getTransactionsVotesData]);

  useEffect(() => {
    if (getTransactionsTransfersData) {
      const sortedVotes = getTransactionsTransfersData
        .map((item) => {
          return { ...item, actionTime: item.actionTime };
        })
        .sort((a, b) => {
          return b.actionTime - a.actionTime;
        });

      setSortedTransactions(sortedVotes);
    }
  }, [getTransactionsTransfersData]);

  useEffect(() => {
    dispatch(getTransactionsTransfers());
    dispatch(getTransactionsVotes());
    dispatch(getAccount("nixiee"));
    dispatch(getAllAccounts());
  }, []);

  useEffect(() => {
    if (account !== undefined) {
      dispatch(getTransactionsByName(account.name));
      dispatch(getTransactionsTransfersByAccount(account.name));
      dispatch(getTransactionsVotesByAccount(account.name));
      getDynamicGlobalProperties();
    }
  }, [account]);

  useEffect(() => {
    if (globalProps?.available_account_subsidies && account) {
      setAccountPower(
        vestToSp(
          account?.vesting_shares?.split(" ")[0],
          globalProps?.total_vesting_shares?.split(" ")[0],
          globalProps?.total_vesting_fund_steem?.split(" ")[0]
        )
      );
    }
  }, [globalProps]);

  useEffect(() => {
    const estimatePercentBlaBla = async (steem_power, percent) => {
      if (percent === 100) {
        setGetEstimateReward(await calculateEstimateReward2(steem_power));
        return;
      }

      const decreasedSteem = steem_power * (percent / 1000);

      setGetEstimateReward(await calculateEstimateReward2(decreasedSteem));
    };
    estimatePercentBlaBla(accountPower, percentage);
  }, [percentage]);

  const calculateEstimateReward = async (steemPower) => {
    const response = await axios.post(
      "https://dashboard.blokfield.io/delegatevotingapi/calculate",
      { sp: Number(steemPower) }
    );
    setCalculateResponse(response.data);
  };

  const calculateEstimateReward2 = async (steemPower) => {
    const response = await axios.post(
      "https://dashboard.blokfield.io/delegatevotingapi/calculate",
      { sp: Number(steemPower) }
    );
    return response.data;
  };

  const getDynamicGlobalProperties = async () => {
    const result = await axios.post("https://api.justyy.com/", {
      jsonrpc: "2.0",
      method: "condenser_api.get_dynamic_global_properties",
      params: [],
      id: 1,
    });
    setGlobalProps(result.data.result);
  };

  function vestToSp(vestingShares, totalVestingShares, totalVestingFundBlurt) {
    return (
      parseFloat(totalVestingFundBlurt) *
      (parseFloat(vestingShares) / parseFloat(totalVestingShares))
    );
  }

  const columnsTransfers = [
    {
      name: "Delegator Name",
      selector: (row) => row.delegator,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) => new Date(row.actionTime).toLocaleString(),
      sortable: true,
    },
    {
      name: "Delegated SP",
      selector: (row) => row.delegatedSp,
      sortable: true,
    },
    {
      name: "Vote Weight",
      selector: (row) => <span>%{row.voteWeight}</span>,
      sortable: true,
    },
    {
      name: "Estimate Reward",
      selector: (row) => row.estimateUpvoteReward.toFixed(3) + " STEEM",
      sortable: true,
    },
    {
      name: "Effective SP",
      selector: (row) => row.effectiveSp,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status ? (
          <span className="text-green-700">Success</span>
        ) : (
          <span className="text-red-600">Failed</span>
        ),
      sortable: true,
    },
  ];

  const columsVotes = [
    {
      name: "Delegator Name",
      selector: (row) => row.delegator,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) => new Date(row.actionTime).toLocaleString(),
      sortable: true,
    },
    {
      name: "Delegated SP",
      selector: (row) => row.delegatedSp,
      sortable: true,
    },
    {
      name: "Permlink",
      selector: (row) => (
        <a
          href={`https://steemit.com/steem/@${row.author}/${row.permlink}`}
          rel="noreferrer"
          className="text-emerald-600"
          target="_blank"
        >
          {row.permlink}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Vote Weight",
      selector: (row) => <span>%{row.voteWeight}</span>,
      sortable: true,
    },
    {
      name: "Estimate Reward",
      selector: (row) => row.estimateUpvoteReward.toFixed(3) + " STEEM",
      sortable: true,
    },
    {
      name: "Effective SP",
      selector: (row) => row.effectiveSp,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status ? (
          <span className="text-green-700">Success</span>
        ) : (
          <span className="text-red-600">Failed</span>
        ),
      sortable: true,
    },
  ];

  const columsAllAccounts = [
    {
      name: "Delegator Name",
      selector: (row) => row.delegator,
      sortable: true,
    },
    {
      name: "Delegate Time",
      selector: (row) => new Date(row.delegateTime).toLocaleString(),
      sortable: true,
    },
    {
      name: "Delegated Amount",
      selector: (row) => row.delegateAmount,
      sortable: true,
    },
  ];

  return (
    <>
      <div className="flex flex-col justify-center items-center p-4 gap-4 md:ml-[220px]">
        {contentID === 0 ? (
          <>
            <div className="flex flex-col md:flex-row justify-between items-center w-full shadow-md shadow-blue-200 bg-white rounded-md text-blue-500 p-4 border-[2px] border-blue-300">
              <div className="flex flex-col justify-center items-center w-full border-r">
                <h3 className="font-bold text-md">Total SP</h3>
                <h3 className="text-lg">{getAccountData?.totalSp?.toFixed(3)}</h3>
              </div>
              <div className="flex flex-col justify-center items-center w-full border-r">
                <h3 className="font-bold text-md">Vote Power</h3>
                <h3 className="text-lg">{getAccountData?.votePower}%</h3>
              </div>
              <div className="flex flex-col justify-center items-center w-full border-r">
                <h3 className="font-bold text-md">Countdown</h3>
                <h3 className="text-lg">
                  {hours < 10 ? `0${hours}` : hours}:
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </h3>
              </div>
              <div className="flex flex-col justify-center items-center w-full">
                <h3 className="font-bold text-md">Vote Value</h3>
                <h3 className="text-lg">{`${
                  getAccountData?.voteValue?.split(" ")[0]
                } USD`}</h3>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center w-full gap-[20px]">
              <h3 className="flex justify-start items-center w-full text-[30px] font-bold text-gray-500">
                Votes
              </h3>
              <div className="flex flex-col w-full justify-center items-center">
                <DataTable
                  columns={columsVotes}
                  data={sortedVotes}
                  progressPending={isGetTransactionsVotesLoading}
                  pagination
                />
              </div>
              <h3 className="flex justify-start items-center w-full text-[30px] font-bold text-gray-500">
                Non-Post Rewards
              </h3>
              <div className="flex flex-col w-full justify-center items-center">
                <DataTable
                  columns={columnsTransfers}
                  data={sortedTransactions}
                  progressPending={isGetTransactionsTransfersLoading}
                  pagination
                />
              </div>
            </div>
          </>
        ) : contentID === 1 ? (
          account.id ? (
            <>
              <div className="flex flex-col md:flex-row justify-between items-center w-full shadow-md shadow-blue-200 bg-white rounded-md text-blue-500 p-4 border-[2px] border-blue-300">
                <div className="flex flex-col justify-center items-center w-full border-r">
                  <h3 className="font-bold text-md">Total SP</h3>
                  <h3 className="text-lg">
                    {getTransactionsByNameData?.totalSp}
                  </h3>
                </div>
                <div className="flex flex-col justify-center items-center w-full border-r">
                  <h3 className="font-bold text-md">Vote Power</h3>
                  <h3 className="text-lg">
                    {getTransactionsByNameData?.votePower}%
                  </h3>
                </div>
                <div className="flex flex-col justify-center items-center w-full border-r">
                  <h3 className="font-bold text-md">Countdown</h3>
                  <h3 className="text-lg">
                    {hours < 10 ? `0${hours}` : hours}:
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </h3>
                </div>
                <div className="flex flex-col justify-center items-center w-full">
                  <h3 className="font-bold text-md">Vote Value</h3>
                  <h3 className="text-lg">
                    {getTransactionsByNameData?.voteValue}
                  </h3>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center w-full gap-[20px]">
                <h3 className="flex justify-start items-center w-full text-[30px] font-bold text-gray-500">
                  Transfers
                </h3>
                <div className="flex flex-col w-full justify-center items-center">
                  <DataTable
                    columns={columnsTransfers}
                    data={getTransactionsTransfersByAccountData}
                    pagination
                  />
                </div>
                <h3 className="flex justify-start items-center w-full text-[30px] font-bold text-gray-500">
                  Votes
                </h3>
                <div className="flex flex-col w-full justify-center items-center">
                  <DataTable
                    columns={columsVotes}
                    data={getTransactionsVotesByAccountData}
                    pagination
                  />
                </div>
              </div>
            </>
          ) : (
            <h3 className="text-gray-700 font-bold p-2 rounded-[5px]">
              Please login to your steem account!
            </h3>
          )
        ) : contentID === 2 ? (
          <div className="mt-[40px] flex flex-col justify-center items-center w-full gap-4">
            <div className="flex flex-col md:flex-row justify-between items-center border-[2px] p-4 border-blue-300 rounded-[20px] w-full gap-4">
              <div className="flex flex-col md:flex-row gap-2">
                <input
                  type="number"
                  className="appearance-none block w-full md:w-48 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 font-bold"
                  placeholder="Steem Amount"
                  value={calculate}
                  onChange={(e) => setCalculate(e.target.value)}
                />
                <button
                  className="bg-blue-300  md:mx-2 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
                  onClick={() => calculateEstimateReward(calculate)}
                >
                  Calculate
                </button>
              </div>

              {calculateResponse && (
                <div className="flex flex-col md:flex-row w-full justify-end items-center gap-4 md:gap-10">
                  <h3 className="text-gray-700 font-bold border-[2px] p-2 rounded-[5px] w-full">
                    Author Reward: {calculateResponse?.authorReward?.toFixed(3)}
                    Steem
                  </h3>
                  <h3 className="text-gray-700 font-bold border-[2px] p-2 rounded-[5px] w-full">
                    Steem Amount: {calculateResponse?.steemAmount?.toFixed(3)}
                    Steem
                  </h3>
                  <h3 className="text-gray-700 font-bold border-[2px] p-2 rounded-[5px] w-full">
                    Vote Weight: {calculateResponse?.voteWeight}%
                  </h3>
                </div>
              )}
            </div>
            {account.balance ? (
              <div className="flex flex-col md:flex-row justify-between items-center border-[2px] p-4 border-blue-300 rounded-[20px] w-full gap-4">
                <div className="flex h-[45px] bg-gray-200 justify-center items-center text-gray-700 font-bold px-3 ">
                  <h3 className="whitespace-nowrap">
                    Account Steem Power: {accountPower && accountPower}
                  </h3>
                </div>
                <div className="flex gap-1 w-full flex-col md:flex-row">
                  <div className="flex text-gray-700 font-bold border-[2px] border-blue-300 p-2 rounded-[5px] gap-2">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="percentage25"
                      name="percentage"
                      onChange={() => setPercentage(25)}
                    />
                    <label
                      className="cursor-pointer w-full"
                      htmlFor="percentage25"
                    >
                      25%
                    </label>
                  </div>
                  <div className="flex text-gray-700 font-bold border-[2px] border-blue-300 p-2 rounded-[5px] gap-2">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="percentage50"
                      name="percentage"
                      onChange={() => setPercentage(50)}
                    />
                    <label
                      className="cursor-pointer w-full"
                      htmlFor="percentage50"
                    >
                      50%
                    </label>
                  </div>
                  <div className="flex text-gray-700 font-bold border-[2px] border-blue-300 p-2 rounded-[5px] gap-2">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="percentage75"
                      name="percentage"
                      onChange={() => setPercentage(75)}
                    />
                    <label
                      className="cursor-pointer w-full"
                      htmlFor="percentage75"
                    >
                      75%
                    </label>
                  </div>
                  <div className="flex text-gray-700 font-bold border-[2px] border-blue-300 p-2 rounded-[5px] gap-2">
                    <input
                      className="cursor-pointer"
                      type="radio"
                      id="percentage100"
                      name="percentage"
                      onChange={() => setPercentage(100)}
                    />
                    <label
                      className="cursor-pointer w-full"
                      htmlFor="percentage100"
                    >
                      100%
                    </label>
                  </div>
                </div>

                <h3 className="text-gray-700 font-bold border-[2px] p-2 rounded-[5px] w-full">
                  Estimate Reward: {getEstimateReward?.authorReward?.toFixed(3)}{" "}
                  Steem
                </h3>
                <h3 className="text-gray-700 font-bold border-[2px] p-2 rounded-[5px] w-full">
                  Vote Power: {getEstimateReward?.voteWeight?.toFixed(3)}%
                </h3>
                <h3 className="text-gray-700 font-bold border-[2px] p-2 rounded-[5px] w-full">
                  Daily Steem Power:{" "}
                  {getEstimateReward?.steemAmount?.toFixed(3)} Steem
                </h3>
              </div>
            ) : null}
          </div>
        ) : contentID === 3 ? (
          <>
            <div className="flex flex-col justify-center items-center w-full gap-[20px]">
              <h3 className="flex justify-start items-center w-full text-[30px] font-bold text-gray-500">
                Delegators List
              </h3>
              <div className="flex flex-col w-full justify-center items-center">
                <DataTable
                  columns={columsAllAccounts}
                  data={getAllAccountsData}
                  progressPending={isGetAllAccountLoading}
                  pagination
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Table;
