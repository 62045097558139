import React, { useEffect, useState } from "react";
import logo from "../../assets/Logo/nixieeLogo.png";

const SideBar = (props) => {
  const [displaySideBar, setDisplaySideBar] = useState(true);
  const handleClick = (id) => {
    props.onButtonClick(id);
  };
  const { showSideBar } = props;

  useEffect(() => {
    setDisplaySideBar(!displaySideBar);
  }, [showSideBar]);

  return (
    <>
      <div className="hidden fixed md:flex flex-col justify-start items-start w-[200px] bg-blue-200 h-[100vh] rounded-r-[20px] shadow-lg shadow-gray-400">
        <div className="flex justify-center items-center w-full py-4">
          <img className="w-[70px] h-[70px]" src={logo} alt="nixiee logo" />
        </div>
        <div className="flex flex-col justify-between items-between h-full">
          <div className="flex flex-col px-4 justify-center items-center w-[200px] gap-4">
            <button
              onClick={() => handleClick(0)}
              className="w-full bg-white text-blue-400 h-10 rounded-[10px] shadow-lg shadow-white border-blue-300 border-[1px] hover:border-blue-600 hover:shadow-blue-300 ease-out duration-300"
            >
              Dashboard
            </button>
            <button
              onClick={() => handleClick(1)}
              className="w-full bg-white text-blue-400 h-10 rounded-[10px] shadow-lg shadow-white border-blue-300 border-[1px] hover:border-blue-600 hover:shadow-blue-300 ease-out duration-300"
            >
              Your History
            </button>
            <button
              onClick={() => handleClick(2)}
              className="w-full bg-white text-blue-400 h-10 rounded-[10px] shadow-lg shadow-white border-blue-300 border-[1px] hover:border-blue-600 hover:shadow-blue-300 ease-out duration-300"
            >
              Calculate
            </button>
            <button
              onClick={() => handleClick(3)}
              className="w-full bg-white text-blue-400 h-10 rounded-[10px] shadow-lg shadow-white border-blue-300 border-[1px] hover:border-blue-600 hover:shadow-blue-300 ease-out duration-300"
            >
              Delegator List
            </button>
          </div>
        </div>
      </div>
      {displaySideBar && (
        <div>
          <div
            onClick={() => setDisplaySideBar(!displaySideBar)}
            className="bg-gray w-full h-full fixed z-40 bg-gray-200 opacity-70"
          ></div>
          <div className="left-0 z-50 fixed md:hidden flex-col justify-start items-start w-[200px] bg-blue-200 h-[100vh] rounded-r-[20px] shadow-lg shadow-gray-400">
            <div className="flex justify-center items-center w-full py-4">
              <img className="w-[70px] h-[70px]" src={logo} alt="nixiee logo" />
            </div>
            <div className="flex flex-col justify-between items-between h-full">
              <div className="flex flex-col px-4 justify-center items-center w-[200px] gap-4">
                <button
                  onClick={() => handleClick(0)}
                  className="w-full bg-white text-blue-400 h-10 rounded-[10px] shadow-lg shadow-white border-blue-300 border-[1px] hover:border-blue-600 hover:shadow-blue-300 ease-out duration-300"
                >
                  Dashboard
                </button>
                <button
                  onClick={() => handleClick(1)}
                  className="w-full bg-white text-blue-400 h-10 rounded-[10px] shadow-lg shadow-white border-blue-300 border-[1px] hover:border-blue-600 hover:shadow-blue-300 ease-out duration-300"
                >
                  Your History
                </button>
                <button
                  onClick={() => handleClick(2)}
                  className="w-full bg-white text-blue-400 h-10 rounded-[10px] shadow-lg shadow-white border-blue-300 border-[1px] hover:border-blue-600 hover:shadow-blue-300 ease-out duration-300"
                >
                  Calculate
                </button>
                <button
                  onClick={() => handleClick(3)}
                  className="w-full bg-white text-blue-400 h-10 rounded-[10px] shadow-lg shadow-white border-blue-300 border-[1px] hover:border-blue-600 hover:shadow-blue-300 ease-out duration-300"
                >
                  Delegator List
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;
