import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  getTransactionsTransfersData: [],
  getTransactionsVotesData: [],
  getAccountData: [],
  getTransactionsByNameData: [],
  getTransactionsTransfersByAccountData: [],
  getTransactionsVotesByAccountData: [],
  isGetTransactionsTransfersLoading: false,
  isGetTransactionsVotesLoading: false,
  getSteemUSDParityData: [],
  getAllAccountsData: [],
  isGetAllAccountLoading: false,
};

export const getTransactionsTransfers = createAsyncThunk(
  "bot/getTransactionsTransfers",
  async (name, thunkAPI) => {
    try {
      const resp = await axios.get(
        `https://dashboard.blokfield.io/delegatevotingapi/transactions/transfers`
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getTransactionsTransfersByAccount = createAsyncThunk(
  "bot/getTransactionsTransfersByAccount",
  async (accountName, thunkAPI) => {
    try {
      const resp = await axios.get(
        `https://dashboard.blokfield.io/delegatevotingapi/transactions/${accountName}/transfers`
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getTransactionsVotesByAccount = createAsyncThunk(
  "bot/getTransactionsVotesByAccount",
  async (accountName, thunkAPI) => {
    try {
      const resp = await axios.get(
        `https://dashboard.blokfield.io/delegatevotingapi/transactions/${accountName}/votes`
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getTransactionsVotes = createAsyncThunk(
  "bot/getTransactionsVotes",
  async (name, thunkAPI) => {
    try {
      const resp = await axios.get(
        `https://dashboard.blokfield.io/delegatevotingapi/transactions/votes`
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getAccount = createAsyncThunk(
  "bot/getAccount",
  async (delegatorName, thunkAPI) => {
    try {
      const resp = await axios.get(
        `https://dashboard.blokfield.io/delegatevotingapi/accounts/${delegatorName}`
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getTransactionsByName = createAsyncThunk(
  "bot/getTransactionsByName",
  async (delegatorName, thunkAPI) => {
    try {
      const resp = await axios.get(
        `https://dashboard.blokfield.io/delegatevotingapi/accounts/${delegatorName}`
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getSteemUSDParity = createAsyncThunk(
  "bot/getSteemUSDParity",
  async (name, thunkAPI) => {
    try {
      const resp = await axios.get(
        `https://api.coingecko.com/api/v3/coins/steem?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`
      );
      return resp.data.market_data.current_price.usd;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const getAllAccounts = createAsyncThunk(
  "bot/getAllAccount",
  async (delegatorName, thunkAPI) => {
    try {
      const resp = await axios.get(
        `https://dashboard.blokfield.io/delegatevotingapi/accounts`
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const getBotSlice = createSlice({
  name: "bot",
  initialState,
  reducers: {},
  extraReducers: {
    // getTransactionsTransfers
    [getTransactionsTransfers.pending]: (state) => {
      state.isGetTransactionsTransfersLoading = true;
    },
    [getTransactionsTransfers.fulfilled]: (state, action) => {
      state.isGetTransactionsTransfersLoading = false;
      state.getTransactionsTransfersData = action.payload;
    },
    [getTransactionsTransfers.rejected]: (state, action) => {
      state.isGetTransactionsTransfersLoading = false;
    },
    // getTransactionsVotes
    [getTransactionsVotes.pending]: (state) => {
      state.isGetTransactionsVotesLoading = true;
    },
    [getTransactionsVotes.fulfilled]: (state, action) => {
      state.isGetTransactionsVotesLoading = false;
      state.getTransactionsVotesData = action.payload;
    },
    [getTransactionsVotes.rejected]: (state, action) => {
      state.isGetTransactionsVotesLoading = false;
    },
    // getAccount
    [getAccount.pending]: (state) => {
      state.isGetAccountLoading = true;
    },
    [getAccount.fulfilled]: (state, action) => {
      state.isGetAccountLoading = false;
      state.getAccountData = action.payload;
    },
    [getAccount.rejected]: (state, action) => {
      state.isGetAccountLoading = false;
    },
    // getTransactionsByName
    [getTransactionsByName.pending]: (state) => {
      state.isGetTransactionsByNameLoading = true;
    },
    [getTransactionsByName.fulfilled]: (state, action) => {
      state.isGetTransactionsByNameLoading = false;
      state.getTransactionsByNameData = action.payload;
    },
    [getTransactionsByName.rejected]: (state, action) => {
      state.isGetTransactionsByNameLoading = false;
    },
    // getTransactionsTransfersByAccount
    [getTransactionsTransfersByAccount.pending]: (state) => {
      state.isGetTransactionsTransfersByAccountLoading = true;
    },
    [getTransactionsTransfersByAccount.fulfilled]: (state, action) => {
      state.isGetTransactionsTransfersByAccountLoading = false;
      state.getTransactionsTransfersByAccountData = action.payload;
    },
    [getTransactionsTransfersByAccount.rejected]: (state, action) => {
      state.isGetTransactionsTransfersByAccountLoading = false;
    },
    // getTransactionsVotesByAccount
    [getTransactionsVotesByAccount.pending]: (state) => {
      state.isGetTransactionsVotesByAccountLoading = true;
    },
    [getTransactionsVotesByAccount.fulfilled]: (state, action) => {
      state.isGetTransactionsVotesByAccountLoading = false;
      state.getTransactionsVotesByAccountData = action.payload;
    },
    [getTransactionsVotesByAccount.rejected]: (state, action) => {
      state.isGetTransactionsVotesByAccountLoading = false;
    },
    // getSteemUSDParity
    [getSteemUSDParity.pending]: (state) => {
      state.isGetSteemUSDParityLoading = true;
    },
    [getSteemUSDParity.fulfilled]: (state, action) => {
      state.isGetSteemUSDParityLoading = false;
      state.getSteemUSDParityData = action.payload;
    },
    [getSteemUSDParity.rejected]: (state, action) => {
      state.isGetSteemUSDParityLoading = false;
    },
    // getAllAccount
    [getAllAccounts.pending]: (state) => {
      state.isGetAllAccountsLoading = true;
    },
    [getAllAccounts.fulfilled]: (state, action) => {
      state.isGetAllAccountLoading = false;
      state.getAllAccountsData = action.payload;
    },
    [getAllAccounts.rejected]: (state, action) => {
      state.isGetAllAccountLoading = false;
    },
  },
});

export default getBotSlice.reducer;
