import SideBar from "./components/SideBar/SideBar";
import NavBar from "./components/NavBar/NavBar";
import Table from "./components/Table/Table";
import { useState } from "react";

function App() {
  const [menuId, setMenuId] = useState(0);
  const [account, setAccount] = useState("");
  const [showSideBar, setShowSideBar] = useState(false);

  const handleButtonClick = (id) => {
    setMenuId(id);
  };

  const handleUsername = (username) => {
    setAccount(username);
  };

  const handleSideBarClick = (bool) => {
    setShowSideBar(bool);
  };

  return (
    <>
      <SideBar onButtonClick={handleButtonClick} showSideBar={showSideBar} />
      <NavBar getAccount={handleUsername} handleSideBar={handleSideBarClick} />
      <Table contentID={menuId} account={account} />
    </>
  );
}

export default App;
